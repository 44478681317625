import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Choose from './components/Choose';
import Spacing from './components/Spacing';
import About from './components/About';
import Footer from './components/Footer';
import Contact from './components/Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Navbar />
      <Hero />
      <Spacing />
      <About />
      <Spacing />
      <Choose />
      <Spacing />
      <Contact />
      <Spacing />
      <Footer />
    </React.StrictMode>
  </BrowserRouter>
);
