import React, { useState } from 'react';
import '../index.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    };
  };

  const scrollToHome = () => {
    const homeSection = document.getElementById('hero-section');
    if(homeSection){
      homeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Fermer le menu après le clic, si nécessaire
  };

  return (
    <div className="w-full shadow-lg font-Oswald fixed top-0 left-0 z-50 bg-slate-10">
      <nav className="flex items-center justify-between bg-slate-100 p-4">
        <h1 className="text-2xl text-black text-primary">Nekketsu.travel</h1>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        <ul className="hidden md:flex space-x-6 text-black">
          <li onClick={scrollToHome} className="cursor-pointer">Home</li>
          <li onClick={scrollToAbout} className="cursor-pointer">About</li>
          <li onClick={scrollToContact} className="cursor-pointer">Destinations</li>
        </ul>
      </nav>
      {isOpen && (
        <div className="md:hidden bg-slate-100">
          <ul className="flex flex-col space-y-4 p-4">
          <li onClick={scrollToHome} className="cursor-pointer">Home</li>
            <li onClick={scrollToAbout} className="cursor-pointer">About</li>
            <li onClick={scrollToContact} className="cursor-pointer">Destinations</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
