import '../index.css';

function Contact() {

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(new FormData(e.target));
  };

  return (
    <div className='p-8 bg-white shadow-lg rounded-lg max-w-lg mx-auto my-10'>
      <p className='text-4xl text-center text-gray-800 font-semibold font-oswald mb-6'>Send us your project</p>
      
      <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
        {/* Input pour le nom d'utilisateur */}
        <div>
          <label className="block text-gray-700 font-medium mb-2" htmlFor="username">
            Your Name
          </label>
          <input
            className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
            type='text'
            name='username'
            id='username'
            placeholder='John Doe'
            required
          />
        </div>

        {/* Input pour l'email */}
        <div>
          <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
            Email Address
          </label>
          <input
            className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
            type='email'
            name='email'
            id='email'
            placeholder='johndoe@example.com'
            required
          />
        </div>

        {/* Textarea pour le message */}
        <div>
          <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
            Project Description
          </label>
          <textarea
            className='w-full px-4 py-2 border border-gray-300 rounded-lg h-32 focus:outline-none focus:ring-2 focus:ring-blue-500'
            name='message'
            id='message'
            placeholder='Describe your project...'
            required
          ></textarea>
        </div>

        {/* Bouton pour envoyer le formulaire */}
        <div className='flex justify-center'>
          <button
            className='px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200'
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
