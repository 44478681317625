import React from 'react';
import '../index.css';

function Footer(){
    return(
        <div className='bg-gray-400 text-black p-4 text-center'>
            <p>&copy; 2024 Nekketsu.travel</p>
        </div>
    )
}

export default Footer;