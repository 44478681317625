import React from 'react';
import conseil from '../img/company.jpg';

function About() {
  return (
    <div id="about-section" className="about-container">
      <section className="p-8 rounded-sm">
        {/* Section avec "About us" à gauche et l'image à droite, centrées */}
        <div className="flex flex-col md:flex-row items-center justify-center md:items-center">
          {/* Section "About us" */}
          <div className="md:w-1/2 bg-gray-200 rounded-md p-4 text-center md:text-left">
            <h1 className="text-3xl text-center text-primary font-oswald mb-4">About us</h1>
            <p className="font-oswald p-2 m-4">
              Chez Nekketsu.Travel, nous croyons que chaque voyageur mérite une expérience unique, parfaitement adaptée à ses envies et à ses passions. Que vous soyez un aventurier en quête de sensations fortes, un amoureux de la nature à la recherche de paysages époustouflants, ou un explorateur urbain avide de culture et de gastronomie, nous sommes là pour vous guider vers votre destination de rêve.
              <br /><br />
              Grâce à notre plateforme intuitive, vous pouvez découvrir des destinations sur mesure en fonction de vos intérêts, de votre budget et de vos préférences. Répondez à quelques questions simples et laissez-nous vous proposer des endroits que vous n'avez peut-être jamais envisagés, mais qui sont faits pour vous.
              <br /><br />
              Explorez le monde avec confiance, que ce soit pour un week-end prolongé, une escapade romantique, ou une aventure hors des sentiers battus. Avec Nekketsu.Travel, votre prochaine destination est à portée de clic.
              <br /><br />
              Voyagez. Explorez. Découvrez. Avec Nekketsu.Travel, trouvez votre destination idéale et partez pour une aventure inoubliable.
            </p>
          </div>

          {/* Image à droite */}
          <div className="md:w-1/4 flex justify-center md:justify-center mt-4 md:mt-0">
            <img src={conseil} className="w-full h-auto md:w-3/4 rounded-lg" alt='image_conseil'/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
