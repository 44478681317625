import React from 'react';
import '../index.css';
import videoPath from '../video/water.mp4';

function Hero() {
    return(

        <div id='hero-section' className='hero-container font-beco relative w-full h-screen overflow-hidden'>
            
            <video 
            src={videoPath}
            className='absolute top-0 left-0 w-full h-full object-cover'
            autoPlay
            loop
            muted
            >
            </video>
            <div className="absolute w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                <h1 className="text-5xl text-white m-20">Welcome to Nekketsu.travel</h1>
            </div>
        </div>
    );
}

export default Hero;