import React from 'react';
import '../index.css';
import la from '../img/losangeles.jpg';
import jap from '../img/japan.jpg';
import oslo from '../img/oslo.jpg';

function Choose() {
  return (
    <div id='contact-section' className="contact-container">
      <section className='p-4 md:p-8 bg-gray-200 gap-4 md:gap-8 m-4 md:m-12 rounded-lg'>
        <div className='text-2xl md:text-3xl p-4 md:p-8 text-primary font-oswald flex justify-center'>
          <p>Des idées pour votre prochaine destination</p>
        </div>
        <div className='text-black grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pb-10 m-4 md:m-10'>
          <div className='rounded-md p-2 md:p-6 text-center hover:scale-105 transition-transform duration-300 ease-in-out shadow-xl md:shadow-2xl border-2 border-zinc-400'>
              <div>
                <h1 className='text-2xl md:text-3xl font-oswald mb-2 md:mb-4'>Tokyo, Japan</h1>
                <div className='flex flex-col justify-center items-center'>
                  <img src={jap} className='mt-4 mb-4 w-24 md:w-32 h-24 md:h-32 rounded-md' alt='Tokyo'></img>
                  <p className='text-left font-oswald text-sm md:text-base'>
                    Explorez Tokyo et découvrez sa culture riche, ses temples ancestraux et sa cuisine exquise.
                  </p>
                </div>
              </div>
            </div>

            <div className='rounded-md p-2 md:p-6 text-center hover:scale-105 transition-transform duration-300 ease-in-out shadow-xl md:shadow-2xl border-2 border-zinc-400'>
              <div>
                <h1 className='text-2xl md:text-3xl font-oswald mb-2 md:mb-4'>Los Angeles, USA</h1>
                <div className='flex flex-col justify-center items-center'>
                  <img src={la} className='mt-4 mb-4 w-24 md:w-32 h-24 md:h-32 rounded-md' alt='Los Angeles'></img>
                  <p className='text-left font-oswald text-sm md:text-base'>
                    Vibrez au rythme de Los Angeles, ville des stars, des plages ensoleillées et de la culture urbaine.
                  </p>
                </div>
              </div>
            </div>

            <div className='rounded-md p-2 md:p-6 text-center hover:scale-105 transition-transform duration-300 ease-in-out shadow-xl md:shadow-2xl border-2 border-zinc-400'>
              <div>
                <h1 className='text-2xl md:text-3xl font-oswald mb-2 md:mb-4'>Oslo, Norway</h1>
                <div className='flex flex-col justify-center items-center'>
                  <img src={oslo} className='mt-4 mb-4 w-24 md:w-32 h-24 md:h-32 rounded-md' alt='Oslo'></img>
                  <p className='text-left font-oswald text-sm md:text-base'>
                    Plongez dans l'ambiance cosmopolite d'Oslo, entre fjords majestueux et scène artistique dynamique.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Choose;
