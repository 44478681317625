import React from 'react';
import '../index.css';

function Spacing(){
    return(
        <div className='p-4 m-4'>

        </div>
    )
}

export default Spacing;